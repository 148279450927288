import { render, staticRenderFns } from "./add.vue?vue&type=template&id=da67f528&scoped=true&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"
import style0 from "./add.vue?vue&type=style&index=0&id=da67f528&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da67f528",
  null
  
)

export default component.exports