<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/staff' }">员工管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增员工</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <div class="special">
          <span class="s-required">*</span>
          <el-form-item label="所属部门" prop="">
            <el-tree
              :data="departmentTreeData"
              node-key="id"
              :props="defaultProps"
              >
              <div class="custom-tree-node" slot-scope="{ node, data }" >
                <span @click.stop="">
                  <el-radio
                  @change="departmentChange"
                  v-model="form.departmentId"
                  :label="data.id" 
                  >
                    {{ node.label }}
                  </el-radio>
                </span>
              </div>
            </el-tree>
          </el-form-item>
        </div>
        
        <el-form-item label="岗位" prop="positionIdList">
          <el-select v-model="form.positionIdList" multiple placeholder="请选择岗位">
            <el-option
              v-for="item in positionFilter"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限" prop="roleId">
          <el-select v-model="form.roleId" placeholder="请选择权限">
            <el-option label="超级管理员" :value="1"></el-option>
            <el-option label="高级管理员" :value="2"></el-option>
            <el-option label="管理员" :value="3"></el-option>
            <el-option label="普通员工" :value="4"></el-option>
          </el-select>
        </el-form-item>

        <div v-if="form.roleId != 4 && form.roleId != null">
          <div class="special">
            <span class="s-required">*</span>
            <el-form-item label="管辖部门">
              <el-collapse v-model="activeDepNames" @change="handleChange">
                <el-collapse-item title="选择管辖部门" name="1">
                  <el-tree
                    ref="menuDepRefs"
                    :data="dataDepTree"
                    show-checkbox
                    node-key="id"
                    default-expand-all
                    :props="depMenuProps">
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
            </el-form-item>
          </div>
        </div>

        <el-form-item label="区域" prop="regionId">
          <el-select v-model="form.regionId" multiple placeholder="请选择区域" @change="regionChange">
            <el-option label="无" :value="0" :key="0"></el-option>
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="册本" prop="zoneId">
          <div v-if="form.regionId[0]==0">
            <el-select value="无">
              <el-option label="无" :value="0" :key="0"></el-option>
            </el-select>
          </div>
          <div v-else>
            <el-cascader
              @change="zoneChange"
              v-model="form.zoneId"
              :options="zoneListFilter"
              :props="props"
              placeholder="请选择册本"
              :show-all-levels="false"
              clearable>
            </el-cascader>
          </div>
        </el-form-item>
        <div class="special">
          <span class="s-required">*</span>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="公司菜单配置" name="1">
                <el-tree
                  ref="menuRefs"
                  :data="dataTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :props="defaultMenuProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </div>

        <el-form-item label="">
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          <el-button size="small" @click="$router.push('/staff')">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        positionIdList: [],
        roleId: null,
        regionId: [],
        zoneId: []
      },
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        sex:[{ required: true, message: '必填项', trigger: 'change' }],
        phone:[{ required: true, message: '必填项', trigger: 'blur' }],
        positionIdList:[{ required: true, message: '必填项', trigger: 'change' }],
        roleId:[{ required: true, message: '必填项', trigger: 'change' }],
        // regionId:[{ required: true, message: '必填项', trigger: 'change' }],
        // zoneId:[{ required: true, message: '必填项', trigger: 'change' }],
      },
      departmentTreeData: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      positionFilter: [],
      position: [],

      // 区域数据
      regionList: [],
      // 册本数据
      props: { multiple: true },
      zoneListFilter: [],//过滤后册本数组
      zoneList: [],//原始册本数据数组
      zoneIds: [],//册本Id数组
      // 控制展开折起
      activeNames: ['1'],
      activeDepNames: ['1'],
      // 初始化数据
      dataTree: [],
      dataDepTree: [],
      // 显示数据格式
      defaultMenuProps: {
        children: 'childTree',
        label: 'title'
      },
      depMenuProps: {
        children: 'childTree',
        label: 'name'
      }
    }
  },
  methods: {
    handleChange(val) {},
    // 菜单配置
    loadTree() {
      let companyId = sessionStorage.getItem('c')
      this.$ajax.post('menuCompany', {
        id: companyId
      }).then(res=> {
        this.dataTree = res.data
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let getDepCheckedKeys
          let getDepHalfCheckedKeys
          if(this.form.roleId!=4) {
            // 管辖部门
            getDepCheckedKeys = this.$refs.menuDepRefs.getCheckedKeys()
            getDepHalfCheckedKeys = this.$refs.menuDepRefs.getHalfCheckedKeys()
            getDepCheckedKeys = getDepCheckedKeys.concat(getDepHalfCheckedKeys)
            // 管辖部门
            if(getDepCheckedKeys == '') {
              this.$message.error('请选择管辖部门')
              return
            }
          }

          // 权限
          let getCheckedKeys = this.$refs.menuRefs.getCheckedKeys()
          let getHalfCheckedKeys = this.$refs.menuRefs.getHalfCheckedKeys()
          getCheckedKeys = getCheckedKeys.concat(getHalfCheckedKeys)
          // 权限
          if(getCheckedKeys == '') {
            this.$message.error('请选择权限配置')
            return
          }
          let form = {
            departmentId: this.form.departmentId,
            responsibleDepartmentIds: getDepCheckedKeys?getDepCheckedKeys:null,
            menuIds: getCheckedKeys,
            name: this.form.name,
            phone: this.form.phone,
            positionIdList: this.form.positionIdList,
            roleId: this.form.roleId,
            sex: this.form.sex,
            regionIdList: this.form.regionId.length>0&&this.form.regionId[0]!=0?this.form.regionId:null,
            zoneIdList: this.zoneIds.length>0?this.zoneIds:null
          }
          
          this.loading = true
          this.$ajax.post("staffCreate", form).then((res) => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push('/staff')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    loadDepartmentList() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTreeData = res.data;
        // 管辖部门数据
        this.dataDepTree = res.data
      });
    },
    departmentChange(id) {
      this.form.positionIdList = "";

      function filterFun(item) {
        return item.departmentId == id;
      }
      this.positionFilter = this.position.filter(filterFun);
    },
    loadPositionList() {
      this.$ajax.get("positionQueryAll").then((res) => {
        this.position = this.getTreeData(res.data);
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
              departmentId: item.departmentId,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    // 获取行政区域
    loadRegion() {
      this.$ajax.post("regionQuery").then((res) => {
        this.regionList = res.data;
      });
    },
    regionChange(ids) {
      this.form.regionId = []
      this.form.zoneId = []
      this.zoneListFilter = []
      let obj = {}
      let arr = []
      ids.forEach(v=>{
        if(ids[0]==0) {
          this.form.regionId = [0]
          return
        }else {
          this.regionList.forEach(item=>{
            if(v==item.id) {
              arr.push({
                id: item.id,
                name: item.name
              })
              this.form.regionId.push(item.id)
            }
          })
        } 
      })
      arr.forEach((v,k)=>{
        if(v==0) return
        obj = {
          value: v.id,
          label: v.name,
          children: []
        }
        this.zoneList.forEach((item,index)=>{
          if(v.id==item.regionId) {
            obj.children.push({
              value: item.id,
              label: item.name
            })
          }
        })
        this.zoneListFilter.push(obj)
      })
    },
    zoneChange(e) {
      this.zoneIds = []
      e.forEach(v=>{
        this.zoneIds.push(v[1])
      })
    },
    loadZoneList() {
      this.$ajax.post("zoneQuery",{
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.zoneList = res.data;
      });
    },
  },
  mounted() {
    this.loadDepartmentList();
    this.loadPositionList();
    this.loadTree()
    this.loadRegion()
    this.loadZoneList()
  }
}
</script>
<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  width: 500px;
}
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
  font-size: 14px;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
.el-select {
  width: 100%;
}
.el-cascader {
  width: 100%;
}
.special {
  position: relative;
  .s-required {
    color: #F56C6C;
    position: absolute;
    top: 12px;
    left: 22px;
    font-size: 14px;
  }
}
</style>